<template>
  <div class="main">
    <TableMain
      tableTitle="Objetivos"
      class="mx-4 mt-4 mb-5"
      actionButtonName="Crear Objetivo"
      :actionButtonLink="'create/' + $route.params._id"
      :headers="['#', 'Nombre']"
      :data="targets"
      :editOption="true"
      :deleteOption="true"
      @editAction="editEvent($event)"
      @deleteAction="deleteEvent($event)"
    >
    </TableMain>
    <Legal></Legal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import TableMain from "@/components/layout/Table";

export default {
  components: { Legal, TableMain },
  beforeCreate() {
    const store = new Storage();
    store.getData("branch").then(branchData => {
      branchData.forEach(branch => {
        if (this.$route.params._id === branch._id) {
          store.getData("city", branch.city).then(cityData => {
            localStorage.setItem(
              "currentLocation",
              "Plan de Trabajo / Objetivos / " + cityData.name
            );
          });
        }
      });
    });
  },
  methods: {
    editEvent(_id) {
      this.$router.push({ name: "Plain Target Edit", params: { _id: _id } });
    },
    deleteEvent(_id) {
      const store = new Storage();
      store.getData("target", _id).then(target => {
        Swal.fire({
          title: "¿Eliminar Objetivo?",
          text: "Se eliminará el objetivo: " + target.name,
          showCancelButton: true,
          confirmButtonText: `Eliminar`,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#dc3545",
          cancelButtonColor: "#6c757d"
        }).then(result => {
          if (result.isConfirmed) {
            Swal.fire({
              icon: "warning",
              title: "Eliminando objetivo",
              html:
                "Por favor espere mientras se elimina el objetivo</b> " +
                '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              stopKeydownPropagation: true
            });
            store.deleteData("optima", "target", _id).then(() => {
              Swal.fire({
                icon: "success",
                title: "Objetivo eliminado",
                text: "Todos los datos han sido sincronizados"
              }).then(() => {
                this.$router.go();
              });
            });
          }
        });
      });
    }
  },
  created() {
    const store = new Storage();
    store.getData("target").then(target => {
      target.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
      target.forEach(area => {
        if (area.branch === this.$route.params._id) {
          this.targets.push([area._id, area.name]);
        }
      });
    });
  },
  data() {
    return {
      targets: []
    };
  },
  name: "Target Branch"
};
</script>
